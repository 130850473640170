import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Alert, Container, Card, CardBody, CardFooter } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAgreementAcceptanceMutation } from "../services/messanaApi";


const Agreement = ({ email }) => {

    const { t } = useTranslation();
    let navigate = useNavigate();
    const [disabled, setDisabled] = useState(true);
    const [agreementAcceptance] = useAgreementAcceptanceMutation();


    return (
        <>
            <Fragment>
                {/* <!-- Row --> */}
                <div className="page main-signin-wrapper br-0-f">

                    <Row className="signpages text-center">
                        <Col className=" mx-auto">
                            <Card>
                                <CardBody>
                                    <div className="text-center">
                                        <img
                                            src={require("../assets/img/brand/logo-messanaitalia.png")}
                                            className="header-brand-img mb-4 mx-auto"
                                            alt="logo-light"
                                            height={100}
                                        />
                                        <h1 className="mt-4 mb-3">{t("agreement.title")}</h1>
                                        <p className="tx-18 text-dark" style={{ textAlign: "left" }}>
                                            {t("agreement.body")}
                                            Le disposizioni che seguono disciplinano i rapporti commerciali con la MESSANA ITALIA s.r.l.s. con sede in Via delle Risorgive 48, 33080 Porcia PN – Italy, P. IVA 01807040934 (d'ora in poi MESSANA ITALIA) e il ruolo di "PonteRadius" o PR, categoria Progettista (PR/P) o Operatore Commerciale (PR/OC).<br /><br />
                                            <h3>Articolo 1. Premesse</h3>
                                            MESSANA ITALIA è una società che opera con esperti del settore dell'impiantistica, termoidraulica, climatizzazione, secondo una filosofia progettuale e costruttiva improntata all'efficienza, al benessere abitativo e funzionale al risparmio energetico, ed ha ideato il sistema radiante RADIUS (pannelli radianti, regolazioni, Unità Trattamento Aria, unità di distribuzione e produzione) nelle diverse versioni e con i relativi accessori così come illustrati nel sito https://www.messanaitalia.com/ che la stessa vuole commercializzare ad operatori del settore dotati di partita IVA (installatori, imprese edili, operatori dell’impiantistica e dell’edilizia) attraverso la rete internet, anche con l'aiuto del PonteRadius.<br />
                                            Il presente portale permette al PonteRadius di gestire tutti i propri contatti e vendite in modo tutelato. Esso non sostituisce ma integra lo strumento già esistente nel quale il PR è iscritto e presente ma permette, a chi lo desidera, di veder protetto e valorizzato il proprio lavoro e portafoglio clienti.<br />
                                            <br />
                                            Di seguito vengono descritte le caratteristiche del portale e le relative modalità d’uso.
                                            <ol style={{ listStyleType: "decimal" }}>
                                                <li>Il PonteRadius deve innanzitutto effettuare la propria iscrizione a tale strumento supplementare compilando la relativa scheda, leggere le presenti istruzioni e rimanere in attesa dell’accettazione da parte della Messana Italia.</li>
                                                <li>Ottenuta l’accettazione lo strumento risulterà operativo per il PR.</li>
                                                <li>Gli ordini che verranno effettuati dal PR implicheranno l’inserimento automatico del proprio cliente nel portale e da quel momento qualsiasi ulteriore vendita che il cliente andasse ad effettuare, anche senza il passaggio attraverso il PR, determineranno la maturazione della relativa spettanza. Si verrà quindi a creare in automatico il portafoglio clienti del PR.</li>
                                                <li>Qualora altri PR dovessero successivamente cercare di inserire un ordine per lo stesso cliente saranno informati che esso è già legato ad un PR titolare del contatto e non potranno effettuare l’ordine.</li>
                                                <li>Anche nel caso in cui il cliente presente nel portafoglio dovesse effettuare un ordine in autonomia il PR cui è legato maturerà la spettanza.</li>
                                                <li>Fino a che un nuovo cliente non accetterà la privacy che gli verrà trasmessa, non sarà legato al Ponteradius e quindi il Ponteradius non potrà ricevere le spettanze sui suoi ordini.</li>
                                                <li>Dopo 1 settimana che il cliente non ha accettato la privacy, viene inviata una email al Ponteradius per avvertirlo di questo e spronarlo a contattare il cliente</li>
                                                <li>La spettanza del Ponteradius è legata all'ordine, quindi può essere modificata a mano di ordine in ordine</li>
                                                <li>Dopo 9 mesi che il Ponteradius e i suoi clienti non fanno ordini, viene inviata una email per avvertirlo che 3 mesi dopo il suo portafoglio clienti sarà eliminato.</li>
                                                <li>Dopo 12 mesi che il Ponteradius e i suoi clienti non fanno ordini, il suo portafoglio viene svuotato, quindi i suoi clienti vengono sganciati da lui e quindi rimangono orfani di Ponteradius e il Ponteradius precedente non riceve più le spettanze degli ordini futuri.</li>
                                            </ol>
                                            Questo strumento è molto importante per tutti i PR, sia progettisti che commerciali. Per quelli che acquistano e rivendono è una tutela ulteriore.<br />
                                            Rimangono valide tutte le clausole contrattuali del contratto originale.
                                        </p>
                                        <Form.Check // prettier-ignore
                                            className="text-dark"
                                            type='checkbox'
                                            id={`default-checkbox`}
                                            label={t("agreement.confirm")}
                                            onChange={() => { setDisabled(!disabled) }}
                                        />
                                        <Button
                                            disabled={disabled}
                                            className="btn btn-primary"
                                            onClick={() => {
                                                agreementAcceptance({ body: { email: email } })
                                            }}
                                        >
                                            {t("agreement.button")}
                                        </Button>
                                    </div>

                                </CardBody>

                            </Card>

                        </Col>
                    </Row>
                </div>


                {/* <!-- End Row --> */}
            </Fragment>
        </>
    );
}

Agreement.propTypes = {};

Agreement.defaultProps = {};

export default Agreement;
