import React from "react";
import { Col } from 'react-bootstrap';


const LeftCol = ({ text }) => {
  return (
    <Col
      lg={6}
      xl={5}
      className="d-none d-lg-block text-center"
    >
      <div className="mt-5 pt-5 p-2">
        <img
          src={require("../assets/img/brand/logo_radiant.png")}
          className="header-brand-img mb-4 mx-auto"
          alt="logo-light"
          height={150}
        />
        <div className="clearfix"></div>
        <span className="tx-13 mb-5 mt-xl-0" style={{ opacity: 1 }} dangerouslySetInnerHTML={{ __html: text }}>
        </span>
      </div>
    </Col>
  );
};

export default LeftCol;
