import React, {useEffect} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import Loader from "../App/Loader/Loader";


const Auth = () => {
    let navigate = useNavigate();
    const refreshToken = localStorage.getItem("refresh");
    useEffect(() => {
        if(!!refreshToken){
            let path = `${process.env.PUBLIC_URL}/dashboard`;
            navigate(path);
        }
    });
  return (
    <div >
        {!refreshToken ? <Outlet/> : <Loader/>}
    </div>
  );
};

export default Auth;
