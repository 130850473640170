import React, {Fragment, useEffect, useState} from "react";
import { useNavigate, useSearchParams} from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Row,
  Alert,
  Card,
  Container,
} from "react-bootstrap";
import LeftCol from "./LeftCol";
import { useTranslation} from 'react-i18next';
import VerificationSuccess from "./VerificationSuccesss";
import {useCheckToVerifyEmailQuery, useVerifyEmailCodeMutation} from "../services/messanaApi";
import Agreement from "./Agreement";



const VerificationCode = () => {
  const { t } = useTranslation();
  const [err, setError] = useState("");
  const [data, setData] = React.useState(["","","","","",""]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState("default");
  const [verifyCode] = useVerifyEmailCodeMutation();
  const check = useCheckToVerifyEmailQuery({email:searchParams.get('email')}, {skip:!searchParams.get('email')});
  let navigate = useNavigate();

  useEffect(() => {
    if(!searchParams.get('email')){
      let path = `${process.env.PUBLIC_URL}/`;
      navigate(path);
    }
  }, []);

  useEffect(() => {
    if(check.isError && !check.isFetching){
      if(check.error.status === "FETCH_ERROR"){
        let path = `${process.env.PUBLIC_URL}/503`;
        navigate(path);
      }else if(check.error.status === 404) {
        let path = `${process.env.PUBLIC_URL}/`;
        navigate(path);
      }else if(check.error.status === 400){
        setStatus("success")
      }
    }
  }, [check]);


  const changeHandler = (e) => {
    const index = parseInt(e.target.name);
    if(e.target.value.length <= 1 && !isNaN(e.target.value)) {
      const change = data.map((value, i) => {
        if (i === index) {
          return e.target.value;
        } else {
          return value;
        }
      });
      setData(change);
    }

  }

  const verify = () => {
    if(data.indexOf("") !== -1){
      setError(t("verification.error"));
    }else {
      verifyCode({body: {email: searchParams.get('email'), code: data.join('')}}).then(value => {
        if(value.error && value.error.status === "FETCH_ERROR"){
          let path = `${process.env.PUBLIC_URL}/503`;
          navigate(path);
        }
        if(value.error){
          setError(t("verification.error"));
        }else {
          setStatus('agreement')
        }
      });
    }
  }

  return (
      <Fragment>
        {!check.isFetching && <Fragment>
          {status === "default" && <div className="page main-signin-wrapper">
            <Row className="signpages text-center">
              <Col md={12} className="col-md-12">
                <Card>
                  <Row className="row-sm">
                    <LeftCol/>
                    <Col lg={6} xl={7} xs={12} sm={12} className=" login_form ">
                      <Container fluid>
                        <Row className=" row-sm">
                          <Card.Body className="mt-2 mb-5 pb-5">
                            <img
                                src="https://www.messanaitalia.com/wp-content/uploads/2023/09/logo-messanaitalia-vector-2.svg"
                                className=" d-lg-none header-brand-img text-start float-start mb-4 auth-light-logo"
                                alt="logo"
                            />
                            <img
                                src="https://www.messanaitalia.com/wp-content/uploads/2023/09/logo-messanaitalia-vector-2.svg"
                                className=" d-lg-none header-brand-img text-start float-start mb-4 auth-dark-logo"
                                alt="logo"
                            />
                            <div className="clearfix"></div>
                            <h5 className="text-center mb-2">{t("verification.title")}</h5>
                            <p className="mb-4 text-muted tx-13 ms-0 text-center">
                              {t("verification.body")}
                            </p>
                            {
                                err && <Alert variant="danger">{err}</Alert>
                            }

                            <Form className="mt-5">
                              <Row className="no-gutters mb-5 px-xl-5">
                                {data.map((value, index) => {
                                  return <Col className="px-0 mx-1 " key={index}>
                                    <Form.Control
                                        className={"form-control" + (err && " border-danger")}
                                        type="text"
                                        name={index.toString()}
                                        value={value}
                                        onChange={(e) => changeHandler(e)}

                                    />

                                  </Col>
                                })}
                              </Row>

                              <Button
                                  onClick={() => verify()}
                                  className="btn ripple btn-main-primary btn-block mt-2"
                              >
                                {t("verification.button")}
                              </Button>
                            </Form>
                          </Card.Body>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>}
          {status === "success" &&
              <VerificationSuccess body={t("verification.successBody")} button={t("verification.successButton")}
                                   title={t("verification.successTitle")}/>}
          {status === "agreement" && <Agreement email={searchParams.get('email')}/>}
        </Fragment>}
      </Fragment>
      );
};
VerificationCode.propTypes = {};

VerificationCode.defaultProps = {};

export default VerificationCode;
