import React, { Fragment} from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import "./i18n"
import Loader from "./App/Loader/Loader";
import Auth from "./Authentication/auth";
import {Provider} from "react-redux";
import {store} from "./store";
import VerificationCode from "./Authentication/VerificationCode";
// Dashboard
const Dashboard = React.lazy(() => import("./Pages/Dashboard"))
const App = React.lazy(() => import("./App/app"));
// AdvanceUi
const Error404 = React.lazy(() => import("./Pages/Errors/Error-404"))
const Error503 = React.lazy(() => import("./Pages/Errors/Error-503"))


//Messana
const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const AuthSignup = React.lazy(() => import("./Authentication/Signup"));
const User = React.lazy(() => import("./Pages/Users/User"));
const MOrders = React.lazy(() => import("./Pages/Orders/Orders"));
const MOrder = React.lazy(() => import("./Pages/Orders/Order"));
const Users = React.lazy(() => import("./Pages/Users/Users"));
const NewOrder = React.lazy(() => import("./Pages/Orders/NewOrder"));
const NewClient = React.lazy(() => import("./Pages/Users/NewClient"));
const ForgotPassword = React.lazy(() => import("./Authentication/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./Authentication/ResetPassword"));
const Privacy = React.lazy(() => import("./Authentication/Privacy"));

const Root = () => {
  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter>
          <React.Suspense fallback={<Loader />}>
            <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
              <Route index element={<AuthLogin />} />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/login`}
                element={<AuthLogin />}
              />
              <Route
                  path={`${process.env.PUBLIC_URL}/authentication/forgotpassword`}
                  element={<ForgotPassword />}
              />
              <Route
                  path={`${process.env.PUBLIC_URL}/authentication/reset-password`}
                  element={<ResetPassword />}
              />
              <Route
                  path={`${process.env.PUBLIC_URL}/authentication/privacy-acceptance`}
                  element={<Privacy />}
              />
                <Route
                path={`${process.env.PUBLIC_URL}/authentication/signup`}
                element={<AuthSignup />}
              />
              <Route
                  path={`${process.env.PUBLIC_URL}/authentication/verification`}
                  element={<VerificationCode />}
              />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/`}
                element={<App/>}>
                <Route>
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard`}
                    element={<Dashboard />}
                  />
                </Route>
                {/*Messana*/}
                <Route
                  path={`${process.env.PUBLIC_URL}/messana/user`}
                  element={<User/>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/messana/users`}
                  element={<Users/>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/messana/orders`}
                  element={<MOrders />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/messana/order`}
                  element={<MOrder />}
                />

                <Route
                    path={`${process.env.PUBLIC_URL}/messana/order/new`}
                    element={<NewOrder />}
                />
                <Route
                    path={`${process.env.PUBLIC_URL}/messana/user/new`}
                    element={<NewClient />}
                />

              </Route>
              <Route path={`${process.env.PUBLIC_URL}/503`} element={<Error503 />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    </Fragment>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);




