import {configureStore} from '@reduxjs/toolkit';
import authReducer from '../src/Authentication/authSlice';
import {messanaApi} from "./services/messanaApi";

export const store = configureStore({
    reducer:{
        [messanaApi.reducerPath]: messanaApi.reducer,
        auth : authReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(messanaApi.middleware)
});