import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'auth',
    initialState: { user: null, tokens: null, valid:false },
    reducers: {
        setCredentials: (
            state,
            {
                payload: { user, tokens },
            }
        ) => {
            state.user = user
            state.tokens = tokens
            state.valid = true
        },
        setTokens: (
            state,
            {
                payload: { tokens },
            }
        ) => {
            state.tokens = tokens
            localStorage.setItem(process.env.REACT_APP_TOKEN_NAME,JSON.stringify(tokens.refreshToken));
        },
        setUser: (state, { payload: { user } }) => {
            state.user = user
            state.valid = true
        },
        loggedOut: (state) => {
            state.user = null;
            state.valid = false;
            state.tokens = null;
            localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME)
        },
        reFetchUser: (state) => {
            state.valid = false
        }
    },
})

export const {
    setCredentials,
    setTokens,
    loggedOut,
    setUser,
    reFetchUser
} = slice.actions

export default slice.reducer

export const selectCurrentUser = (state) => state.auth
export const getValidity = (state) => state.auth.valid