import React, { Fragment } from 'react';
import { Col, Card, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
const VerificationSuccess = ({body,title,button}) => {
  const {t} = useTranslation();
  return(
      <Fragment>
        {/* <!-- Page --> */}
        <div className="page main-signin-wrapper br-0-f">

          <Row className="signpages ext-center">
            <Col md={7} className=" mx-auto">
                  <div className="text-center">
                    <img
                        src={require("../assets/img/brand/logo_radiant.png")}
                        className="header-brand-img mb-4 mx-auto"
                        alt="logo-light"
                        height={100}
                    />
                    <h1 className="mt-4 mb-3">{title}</h1>
                    <p className="tx-18 text-muted">
                      {body}
                    </p>
                      {button && <Link
                          to={`${process.env.PUBLIC_URL}/`}
                          className="btn btn-primary"
                      >
                          {button}
                      </Link>}
                  </div>
            </Col>
          </Row>
        </div>


        {/* <!-- End Page -- */}
      </Fragment>)};

VerificationSuccess.propTypes = {};

VerificationSuccess.defaultProps = {};

export default VerificationSuccess;
