import React from "react";
import {Spinner} from "react-bootstrap";
const Loader = () =>
    <div className="loader-img text-center">
        <Spinner animation="border" variant="primary" />
    </div>;

Loader.propTypes = {};

Loader.defaultProps = {};
export default Loader;
